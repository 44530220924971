import React, { useContext, useRef, useState } from 'react';
import LoadingDots from '../../../../../../../components/LoadingDots/LoadingDots';
import ModalInformation from '../../../../../../../components/ModalInformation/ModalInformation';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../../../../intl';
import styles from './UploadFile.module.scss';
import * as translations from './intl';
import FileItem from '../FileItem';
import { AppInsightTrackContext } from '../../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { extensions } from '../../../../../../../utils/fileExtensionsValidade';
import AddIcon from '../../../../../../../assets/icons/AddIcon';
import ButtonWithIcon from '../../../../../../../components/ButtonWithIcon/ButtonWithIcon';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const UploadFile = props => {
  const { values, setFieldValue } = props;
  const fileReference = useRef();
  const { actions } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { modal } = actions;
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const intl = translate(translations);
  const [loading, setLoading] = useState(false);

  const isSizeKbLimitPossible = (sizeKbFile, document_name, sizeMBFile) => {
    if (sizeKbFile > 1500) {
      trackEventUserAction(
        '#### (UPLOAD) O TAMANHO DO ARQUIVO INSERIDO PARA UPLOAD É MAIOR DO QUE O PERMITIDO ####'
      );
      setLoading(false);
      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.ERROR_MAX_FILESIZE}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      return false;
    }
    return true;
  };

  const isFileTypeAcceptable = (content_type, document_name, sizeMBFile) => {
    if (!content_type || extensions.indexOf(content_type) < 0) {
      trackEventUserAction(
        '#### (UPLOAD) O TIPO DO ARQUIVO INSERIDO PARA UPLOAD NÃO É PERMITIDO ####'
      );
      setLoading(false);
      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_TYPE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      return false;
    }
    return true;
  };

  const getFileData = async () => {
    const file = fileReference.current.files[0];
    const { name: document_name, type: content_type, size } = file;
    const sizeKbFile = parseFloat(size / 1024).toFixed(2);
    const sizeMBFile = parseFloat(sizeKbFile / 1024).toFixed(2);
    try {
      setLoading(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      await new Promise(resolve => {
        reader.onload = () => resolve();
      });

      const content_data = reader.result.substr(reader.result.indexOf(',') + 1);
      const sizeOnLimit = isSizeKbLimitPossible(
        sizeKbFile,
        document_name,
        sizeMBFile
      );
      const typeAcceptable = isFileTypeAcceptable(
        content_type,
        document_name,
        sizeMBFile
      );

      if (sizeOnLimit && typeAcceptable) {
        const fileFormatted = {
          name: document_name,
          file: content_data,
        };

        setFieldValue('files', [...values.files, fileFormatted]);
      }
    } catch (error) {
      trackEventUserAction(
        '#### (UPLOAD) OCORREU UM ERRO GÉNERICO NO UPLOAD DO ARQUIVO ####',
        error
      );
      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.TITLE_UPLOAD_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
    }
    setLoading(false);
  };

  const uploadFile = () => {
    fileReference.current.click();
  };

  const removeFile = uri => {
    trackEventUserAction(
      '#### (UPLOAD) O USUÁRIO ESTÁ REMOVENDO O ARQUIVO ANEXADO ####',
      uri
    );
    const newList = values.files.filter(item => item.uri !== uri);
    trackEventUserAction(
      '#### (UPLOAD) O USUÁRIO REMOVEU O ARQUIVO ANEXADO COM SUCESSO ####'
    );
    setFieldValue('files', newList);
  };

  return (
    <div className={`row ${styles.container}`}>
      <div className={`col-12 ${styles.header}`}>
        <h3 className={styles[theme]}>{intl.SUBTITLE_UPLOAD_FILES}</h3>

        <div>
          <div className={styles.hideInput}>
            <input
              data-testid="inputFile"
              type="file"
              ref={fileReference}
              onChange={getFileData}
              onClick={event => {
                event.target.value = null;
              }}
            />
          </div>
          <ButtonWithIcon
            type="blueWithoutBorder"
            onClick={uploadFile}
            textAlign="right"
            value={
              loading ? (
                <>
                  {intl.LOADING} <LoadingDots />
                </>
              ) : (
                <>{intl.BUTTON_UPLOAD_FILES}</>
              )
            }
            isSVGComponent={true}
            img={<AddIcon />}
            disabled={loading}
          />
        </div>
      </div>

      <div className="col-12">
        {values.files.length === 0 ? (
          <div className={styles.emptyArea}>
            <span className={`${styles.emptyText} ${styles[theme]}`}>
              {intl.EMPTY_MESSAGE}
            </span>
          </div>
        ) : (
          <>
            {values.files.map((file, index) => (
              <FileItem
                key={index}
                file={file}
                index={index}
                removeFile={() => removeFile(file.uri)}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
