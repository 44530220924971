/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { withRouter } from 'react-router';
import styles from './ConsultationInfo.module.scss';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { getConsultationStatusIntl } from '../../../../../../utils/status';
import {
  ConsultationPhoneType,
  ConsultationVideoType,
} from '../../../../../../utils/consultationCategory';
import Button from '../../../../../../components/Button/Button';
import { formatCustomDate } from '../../../../../../utils/date';
import FileList from './FileList/FileList';
import {
  amosConsultationsService,
  amosVideocallsService,
} from '../../../../../../services';
import { AppInsightTrackContext } from '../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import Loading from '../../../../../../components/Loading/Loading';
import DoneAppointmentDetail from './DoneAppointmentDetail/DoneAppointmentDetail';
import { getCountryByInitials } from '../../../../../../intl/utils';
import ButtonWithIcon from '../../../../../../components/ButtonWithIcon/ButtonWithIcon';
import LoadingDots from '../../../../../../components/LoadingDots/LoadingDots';
import AddIcon from '../../../../../../assets/icons/AddIcon';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Formik } from 'formik';
import InfoVideoArea from '../../../TeleconsultationFormFill/Form/AppointmentArea/InfoVideoArea/InfoVideoArea';
import { format } from 'date-fns';
import { extensions } from '../../../../../../utils/fileExtensionsValidade';

const AppointmentInfo = props => {
  const {
    appointment,
    appointmentType,
    customer,
    hasFiles,
    isDone,
    isCanceled,
    showButtons,
    isBeforeCurrentDate,
  } = props;
  const { translate, idiom } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const history = useHistory();
  const [consultation, setConsultation] = useState();
  const [loading, setLoading] = useState(true);
  const [rescheduleForm, setRescheduleForm] = useState(false);
  const fileReference = useRef();
  const { actions } = useContext(StateContext);
  const { modal } = actions;
  const themes = getGlobalTheme();
  const isNullish = prop => {
    if (prop) return prop;
    return '-';
  };

  const optionsLanguage = intl => [
    {
      label: intl.OPTION_LANGUAGE_EN,
      value: 'en',
    },
    {
      label: intl.OPTION_LANGUAGE_PT,
      value: 'pt',
    },
    {
      label: intl.OPTION_LANGUAGE_ES,
      value: 'es',
    },
  ];

  const loadAppointment = useCallback(async () => {
    if (!hasFiles) {
      setConsultation(appointment);
      trackEventUserAction(
        '#### VMO ### Consultation - Consulta carregada (Não possui arquivos)',
        {
          data: appointment,
        }
      );
    } else {
      if (appointmentType === ConsultationPhoneType) {
        trackEventUserAction(
          '#### VMO ### Consultation - Consulta está sendo carregada (Telefone)'
        );
        const { data } = await amosConsultationsService.getPhoneCallById(
          customer,
          appointment.id
        );
        setConsultation(data.data);
        trackEventUserAction(
          '#### VMO ### Consultation - Consulta carregada (Telefone)',
          {
            data: data.data,
          }
        );
      } else {
        trackEventUserAction(
          '#### VMO ### Consultation - Consulta está sendo carregada (Video)'
        );
        const { data } = await amosVideocallsService.getVideoCallById(
          customer,
          appointment.id
        );
        setConsultation(data.data);
        trackEventUserAction(
          '#### VMO ### Consultation - Consulta carregada (Video)',
          {
            data: data.data,
          }
        );
      }
    }
  }, [appointment, appointmentType, customer, hasFiles]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      trackEventUserAction(
        '#### VMO ### Appointment Detail - Loading the appointment information',
        {
          consultation_id: appointment.id,
          appointmentType: appointmentType,
        }
      );
      await Promise.all([loadAppointment()]);

      trackEventUserAction(
        '#### VMO ### Appointment Detail - Loaded appointment information',
        {
          consultation_id: appointment.id,
          appointmentType: appointmentType,
        }
      );
    } catch (error) {
      trackEventUserAction(
        '#### VMO ### Appointment Detail - Error to load appointment',
        { error }
      );
    }
    setLoading(false);
  }, [appointment.id, appointmentType, loadAppointment, trackEventUserAction]);

  const removeFileById = async (files, fileId) => {
    try {
      trackEventUserAction('#### (REMOVE) INICIANDO REMOÇÃO DE ANEXO ####');
      actions.loadingFullScreen.showLoadingFullScreen(intl.REMOVING);
      await amosVideocallsService.removeFile(customer, appointment.id, fileId);
      const updatedPatientFiles = files.filter(item => item.id !== fileId);
      const updatedConsultation = JSON.parse(JSON.stringify(consultation));
      updatedConsultation.patient_files = updatedPatientFiles;
      setConsultation(updatedConsultation);
      actions.loadingFullScreen.hideLoadingFullScreen();
    } catch (err) {
      actions.loadingFullScreen.hideLoadingFullScreen();
      trackEventUserAction(
        '#### (REMOVE) OCORREU UM ERRO GÉNERICO AO REMOVER O ARQUIVO ####',
        err
      );
      modal.showModal(
        false,
        true,
        <ModalInformation type="error" message={intl.SOMETHING_WENT_WRONG} />
      );
    }
  };

  const handleOpenModalRemove = fileId => {
    actions.modal.showModal(
      false,
      true,
      <ModalInformation
        type="question"
        message={intl.MODAL_DELETE_TITLE}
        textBtn={intl.BUTTON_CONTINUE}
        textBtnDeny={intl.BUTTON_CLOSE}
        clickBtn={() => {
          actions.modal.closeModal();
          removeFileById(consultation.patient_files, fileId);
        }}
        clickBtnDeny={() => {
          actions.modal.closeModal();
        }}
      />,
      false,
      false
    );
  };

  const uploadFile = () => {
    fileReference.current.click();
  };

  const isSizeKbLimitPossible = (sizeKbFile, document_name, sizeMBFile) => {
    if (sizeKbFile > 1500) {
      trackEventUserAction(
        '#### (UPLOAD) O TAMANHO DO ARQUIVO INSERIDO PARA UPLOAD É MAIOR DO QUE O PERMITIDO ####'
      );
      setLoading(false);
      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.ERROR_MAX_FILESIZE}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      return false;
    }
    return true;
  };

  const isFileTypeAcceptable = (content_type, document_name, sizeMBFile) => {
    if (!content_type || extensions.indexOf(content_type) < 0) {
      trackEventUserAction(
        '#### (UPLOAD) O TIPO DO ARQUIVO INSERIDO PARA UPLOAD NÃO É PERMITIDO ####'
      );
      setLoading(false);
      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_TYPE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      return false;
    }
    return true;
  };

  const getFileData = async () => {
    console.log('getfile');
    const file = fileReference.current.files[0];
    const { name: document_name, type: content_type, size } = file;
    const sizeKbFile = parseFloat(size / 1024).toFixed(2);
    const sizeMBFile = parseFloat(sizeKbFile / 1024).toFixed(2);
    try {
      trackEventUserAction('#### (UPLOAD) INICIANDO ENVIO DE ANEXO ####');
      const reader = new FileReader();
      reader.readAsDataURL(file);
      await new Promise(resolve => {
        reader.onload = () => resolve();
      });

      const content_data = reader.result.substr(reader.result.indexOf(',') + 1);

      const sizeOnLimit = isSizeKbLimitPossible(
        sizeKbFile,
        document_name,
        sizeMBFile
      );
      const typeAcceptable = isFileTypeAcceptable(
        content_type,
        document_name,
        sizeMBFile
      );

      if (sizeOnLimit && typeAcceptable) {
        actions.loadingFullScreen.showLoadingFullScreen(
          intl.TITLE_SENDING_YOUR_ATTACHMENT
        );
        const fileFormatted = {
          name: document_name,
          file: content_data,
        };

        trackEventUserAction('#### (UPLOAD) ENVIANDO ANEXO ####');
        const { data } = await amosVideocallsService.uploadFile(
          customer,
          fileFormatted
        );
        const uploadId = data.data.upload_id;
        await amosVideocallsService.sendFile(
          customer,
          appointment.id,
          uploadId
        );
        actions.loadingFullScreen.hideLoadingFullScreen();
        trackEventUserAction('#### (UPLOAD) ANEXO ENVIADO COM SUCESSO ####');
        actions.modal.showModal(
          '',
          false,
          <ModalInformation
            type="success"
            message={intl.HAS_BEEN_SENT}
            clickBtn={() => {
              history.go(0);
              actions.modal.closeModal();
            }}
          />
        );
      }
    } catch (error) {
      actions.loadingFullScreen.hideLoadingFullScreen();
      trackEventUserAction(
        '#### (UPLOAD) OCORREU UM ERRO GÉNERICO NO UPLOAD DO ARQUIVO ####',
        error
      );
      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.TITLE_UPLOAD_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
    }
  };

  const showErrorModal = (message, actions) => {
    actions.modal.showModal(
      '',
      true,
      <ModalInformation
        type="error"
        message={message}
        clickBtn={actions.modal.closeModal}
      />
    );
  };

  const handleReschedule = async (values, resetForm) => {
    try {
      trackEventUserAction('#### VMO ### Video - Remarcando consulta ', values);
      actions.loadingFullScreen.showLoadingFullScreen(
        intl.TITLE_SENDING_YOUR_APPOINTMENT
      );
      const formattedDate = format(values.date, 'yyyy-MM-dd');
      setLoading(true);
      await amosVideocallsService.rescheduleDate(
        customer,
        appointment.id,
        values.timezone,
        values.time,
        formattedDate
      );

      actions.loadingFullScreen.hideLoadingFullScreen();
      actions.modal.showModal(
        '',
        false,
        <ModalInformation
          type="success"
          message={intl.TITLE_SUCCESS_REQUEST}
          clickBtn={() => {
            history.go(0);
            actions.modal.closeModal();
          }}
        />
      );

      trackEventUserAction('#### VMO ### Video - Videochamada remarcada', true);

      resetForm();
      setRescheduleForm(false);
      setLoading(false);
    } catch (error) {
      trackEventUserAction(
        '#### VMO ### Video - Erro ao remarcar videochamada',
        error
      );

      actions.loadingFullScreen.hideLoadingFullScreen();
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.data.code === 150
      ) {
        showErrorModal(intl.MESSAGE_NOT_AVAILABLE_DATE_TIME, actions);
        return true;
      } else if (
        error &&
        error.response &&
        error.response.status &&
        error.response.status === 413
      ) {
        showErrorModal(intl.TITLE_ERROR_FILESIZE, actions);
        return false;
      } else {
        showErrorModal(intl.TITLE_UNEXPECTED_ERROR_OCCURRED, actions);
        return false;
      }
    }
  };

  useEffect(() => {
    loadData();
  }, [hasFiles, loadData]);

  return loading ? (
    <div className={`${styles.containerLoading}`}>
      <Loading checkBoxLoading scale="0.8" />
    </div>
  ) : (
    <>
      {rescheduleForm ? (
        <Formik
          initialValues={{ timezone: '', time: '', date: '' }}
          onSubmit={() => console.log('submit')}
        >
          {props => {
            const {
              values,
              errors,
              touched,
              setFieldValue,
              submitForm,
              resetForm,
            } = props;

            return (
              <>
                <InfoVideoArea {...props} />
                <div className={styles.containerButtons}>
                  <div
                    className={`${styles.button} ${styles.boxButton}  ${styles.buttonCreateClaim} ${styles[themes]}`}
                  >
                    <Button
                      css={styles[themes]}
                      type="borderBlue"
                      onClick={() => {
                        resetForm();
                        setRescheduleForm(false);
                      }}
                    >
                      {intl.BTN_BACK}
                    </Button>
                  </div>
                  {!!values.time && (
                    <div
                      className={`${styles.button} ${styles.boxButton}  ${styles.buttonCreateClaim} ${styles[themes]}`}
                    >
                      <Button
                        css={styles[themes]}
                        type="borderBlue"
                        onClick={() => handleReschedule(values, resetForm)}
                      >
                        {intl.RESCHEDULE_TEXT}
                      </Button>
                    </div>
                  )}
                </div>
              </>
            );
          }}
        </Formik>
      ) : (
        <>
          {consultation && (
            <>
              <div className={`row ${styles.appointmentInfo}`}>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <InfoLabelValue
                    label={intl.LABEL_STATUS}
                    value={isNullish(
                      getConsultationStatusIntl(consultation.status, idiom)
                    )}
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <InfoLabelValue
                    label={intl.LABEL_COUNTRY}
                    value={getCountryByInitials(consultation.country, idiom)}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <InfoLabelValue
                    label={intl.LABEL_TIME_ZONE}
                    value={isNullish(consultation.time_zone)}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <InfoLabelValue
                    label={intl.LABEL_LANGUAGE}
                    value={
                      optionsLanguage(intl).find(
                        value => value.value === consultation.language
                      ).label
                    }
                  />
                </div>
                {appointmentType === ConsultationVideoType && (
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <InfoLabelValue
                      label={intl.LABEL_TIME}
                      value={
                        consultation.channel !== 'phone'
                          ? `${formatCustomDate(
                              consultation.date,
                              'YYYY-MM-DD',
                              idiom
                            )}
                      ${consultation.time}`
                          : `${formatCustomDate(
                              consultation.date,
                              'YYYY-MM-DD',
                              idiom
                            )}
                      ${consultation.date.split(' ').pop()}`
                      }
                    />
                  </div>
                )}
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <InfoLabelValue
                    label={intl.LABEL_COUNTRY_CODE}
                    value={isNullish(consultation.patient.phone_prefix)}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <InfoLabelValue
                    label={intl.LABEL_PHONE}
                    value={isNullish(consultation.patient.phone)}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <InfoLabelValue
                    label={intl.LABEL_REASON_APPOINTMENT}
                    value={isNullish(
                      appointmentType === ConsultationPhoneType
                        ? consultation.consultation
                        : consultation.call_reason
                    )}
                  />
                </div>
                {consultation.patient_files.length > 0 && (
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <FileList
                      label={intl.LABEL_FILES}
                      files={consultation.patient_files}
                      removeButton={
                        !isCanceled &&
                        !isDone &&
                        !consultation.doctor_id &&
                        !isBeforeCurrentDate
                      }
                      handleRemove={handleOpenModalRemove}
                    />
                  </div>
                )}
                {consultation &&
                  consultation.status === 'done' &&
                  consultation.doctor_id && (
                    <DoneAppointmentDetail
                      consultation={consultation}
                      isNullish={isNullish}
                    />
                  )}
              </div>

              {!isCanceled &&
                !isDone &&
                appointmentType === ConsultationVideoType &&
                !isBeforeCurrentDate && (
                  <div>
                    <ButtonWithIcon
                      type="blueWithoutBorder"
                      onClick={uploadFile}
                      textAlign="left"
                      value={
                        loading ? (
                          <>
                            {intl.LOADING} <LoadingDots />
                          </>
                        ) : (
                          <>{intl.BUTTON_UPLOAD_FILES}</>
                        )
                      }
                      isSVGComponent={true}
                      img={<AddIcon />}
                      disabled={loading}
                    />
                    <div className={styles.hideInput}>
                      <input
                        data-testid="hideInput"
                        type="file"
                        ref={fileReference}
                        onChange={getFileData}
                        onClick={event => {
                          event.target.value = null;
                        }}
                      />
                    </div>
                  </div>
                )}
              <div className={styles.containerButtons}>
                <div
                  className={`${styles.boxButton} ${styles.buttonCreateClaim} ${styles[themes]}`}
                >
                  {!isCanceled && !isDone && showButtons(false)}
                </div>
                {!isCanceled &&
                  !isDone &&
                  !consultation.doctor_id &&
                  appointmentType === ConsultationVideoType && (
                    <div
                      className={`${styles.button} ${styles.boxButton}  ${styles.buttonCreateClaim} ${styles[themes]}`}
                    >
                      <Button
                        css={styles[themes]}
                        type="borderBlue"
                        onClick={() => setRescheduleForm(true)}
                      >
                        {intl.RESCHEDULE_TEXT}
                      </Button>
                    </div>
                  )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(AppointmentInfo);
